import React from "react"
import { Layout } from "../components/Layout"
import Seo from "../components/Seo"

export default function Careers() {
  return (
    <Layout>
      <Seo title="Вакансии" />
      <div className={`max-w-3xl mx-auto text-sm`}>
        <h2 className={`font-display font-bold uppercase text-xl mb-6`}>
          Мастер по ремонту и монтажу печатных плат
        </h2>
        <div className={"flex-auto flex mb-2 items-center"}>
          <div className={"font-bold  mr-2 text-sm"}>
            Требуемый опыт работы:
          </div>
          <div className={"font-bold  text-sm"}>1–3 года</div>
        </div>
        <div className={"font-bold  text-sm"}>
          Полная занятость, полный день
        </div>
        <div className={`font-display font-bold mt-3 text-base`}>
          Обязанности:
        </div>
        <ul className={`list-disc list-inside`}>
          <li>Диагностика оборудования</li>
          <li>Ремонт электронного оборудования нашего производства</li>
          <li>Настройка оборудования</li>
          <li>Перепрошивка микроконтроллеров</li>
        </ul>
        <div>
          <div className={`font-display font-bold text-base mt-3 text-xs`}>
            Требования:
          </div>
          <ul className={`list-disc list-inside`}>
            <li>Знание основ электроники, радиотехники</li>
            <li>
              Знание технологии монтажа, настройки, диагностика радиоэлектронной
              аппаратуры
            </li>
            <li>Наличие опыта ремонта плат на компонентном уровне</li>
            <li>Умение читать электрические и принципиальные схемы</li>
            <li>
              Опыт ремонта печатных плат на этапе серийного производства, при
              гарантийном и послегарантийном обслуживании
            </li>
            <li>Навыки пайки</li>
            <li>Средне-специальное образование</li>
          </ul>
        </div>

        <h3 className={`font-display font-bold text-base mt-3`}>Условия:</h3>
        <ul className={`list-disc list-inside`}>
          <li>
            График работы 5/2, начало работы возможно в интервале с 7 до 10 утра
          </li>
          <li>
            Заработная плата вся белая и официальная, уважительное отношение к
            ТК РФ
          </li>
          <li>Офис в центре Москвы</li>
        </ul>
        <h3 className={`font-display font-bold text-base mt-6`}>
          Контактная информация:
        </h3>
        <div>Главный конструктор Жданов Михаил Александрович</div>
        <div>
          <a
            href="tel:+7 977 745-04-15"
            className="link_a"
            style={{
              color: "#3399ff",
            }}
          >
            +7 977 745-04-15
          </a>
        </div>
        <div>
          {" "}
          <a
            href="mailto:zhdanov@nposvl.ru"
            className="link_a"
            style={{
              color: "#0099ff",
            }}
          >
            zhdanov@nposvl.ru
          </a>
        </div>
      </div>
    </Layout>
  )
}
